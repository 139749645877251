import React from "react";
import { Link } from "react-router-dom";

import "./nav.css";

const Nav = ({ setUser }) => {
	return (
		<div className="navbar">
			<div>
				<img src="/bloodLogoRed.png" alt="Logo" height="40" />
			</div>
			<ul className="navLinks">
				<li>
					<Link to="/">Home</Link>
				</li>
				<li>
					<Link to="/allDonor">All Donor</Link>
				</li>
				<li>
					<Link to="/gallary">Gallary</Link>
				</li>

				<li className="logout_item">
					<Link onClick={() => {
						setUser(null);
						localStorage.removeItem('blood-token')
					}} to="/">Logout</Link>
				</li>
			</ul>
			<div className="logout">
				<button
					onClick={() => {
						setUser(null);
						localStorage.removeItem('blood-token')
					}}
					className="navBtn"
				>
					Log Out
				</button>
			</div>
		</div>
	);
};

export default Nav;
