import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Nav from "./components/nav/Nav";
import Home from "./screens/home/Home";
import AllDonor from "./screens/donorList/AllDonor";
import Gallary from "./screens/gallary/Gallary";
import Others from "./screens/others/Others";
import useUser from "./hooks/useUser";
import { useMutation } from "react-query";
import {  toast } from 'react-toastify';
function App() {
	const [user, setUser] = useState(null);
	const { LoginRequest, tokenvalidate } = useUser();

	const [msg, setMsg] = useState({
		show: false,
		msg: "",
		type: "",
	});

	const { mutate: loginAction, isLoading } = useMutation(LoginRequest, {
		onSuccess: (res) => {
			localStorage.setItem("blood-token", res.token);
			setUser(res);
			setMsg({
				show: true,
				msg: "Login Successfull",
				type: "danger",
			});
		},
		onError: (err) => {
			setMsg({
				show: true,
				msg: err.response.data.message,
				type: "danger",
			});
		},
	});

	// auth state
	const [state, setState] = useState({
		username: "",
		password: "",
	});

	// auth Handler
	function loginHandler() {
		const { username, password } = state;
		if (username === "") {
			toast.error('Username ?', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else if (password === "") {
			toast.error('Password ?', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			loginAction(state);
		}
	}


	// mutation

	const { mutate: validationToken } = useMutation(tokenvalidate, {
		onSuccess: (res) => {
			localStorage.setItem("blood-token", res.token);
			setUser(res);
			setMsg({
				show: true,
				msg: "Login Successfull",
				type: "danger",
			});
		},
		onError: (err) => {
			setMsg({
				show: true,
				msg: err.response.data.message,
				type: "danger",
			});
		},
	});


	// check refresh token
	useEffect(() => {
		const token = localStorage.getItem('blood-token');
		if(token !== null){
			validationToken({ token })
		}
		// eslint-disable-next-line
	}, [])


	return (
		<>
			<Router>
				<div className="a">
					{user === null ? (
						<>
							<div className="login_wraper">
								{msg.show && <div className={msg.type}>{msg.msg}</div>}
								<div className="form-control">
									<div>Username</div>
									<div>
										<input
											value={state.username}
											onChange={(e) => {
												setState((p) => ({ ...p, username: e.target.value }));
											}}
										/>
									</div>
								</div>
								<div className="form-control">
									<div>Password</div>
									<div>
										<input
											value={state.password}
											onChange={(e) => {
												setState((p) => ({ ...p, password: e.target.value }));
											}}
											type="password"
										/>
									</div>
								</div>
								<div className="form-control">
									<button disabled={isLoading} onClick={loginHandler}>
										{isLoading ? "Authenticating..." : "Login"}
									</button>
								</div>
							</div>
						</>
					) : (
						<>
							<Nav setUser={setUser} />
							<div className="content">
								<Switch>
									<Route path="/" exact component={Home} />
									<Route path="/allDonor" exact component={AllDonor} />
									<Route path="/gallary" exact component={Gallary} />
									<Route path="/others" exact component={Others} />
								</Switch>
							</div>
						</>
					)}
				</div>
			</Router>
		</>
	);
}

export default App;
