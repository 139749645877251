import React from "react";
import ReactDOM from "react-dom";
import "react-loading-skeleton/dist/skeleton.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import App from "./App";
import { ToastContainer } from 'react-toastify';


import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AxiosProvider } from "./lib/AxiosProvider";
const queryClient = new QueryClient();

ReactDOM.render(
	<AxiosProvider>
		<QueryClientProvider client={queryClient}>
			<App />
			<ToastContainer/>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</AxiosProvider>,
	document.getElementById("root")
);
