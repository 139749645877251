import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "./donor.css";
import { useMutation, useQuery , useQueryClient} from "react-query";
import useUtil from "../../hooks/useUtil";
import {  toast } from 'react-toastify';

const AllDonor = () => {
	const { getAllDonorListReqeust, getAllDonorListReqeustLoadMore, blockHandlerRequest } = useUtil();
	// BOdy Ref
	const queryClient = useQueryClient()
	const [totalDoc, setTotalDoc] = useState(0);

	const [text, setText] = useState("");

	const { data: donorData, isLoading: initialLoading } = useQuery(
		["donor-list", text],
		() => getAllDonorListReqeust({skip:0,text }),
		{
			refetchOnWindowFocus: false,
		}
	);

	const [data, setData] = useState([]);

	useEffect(() => {
		if (donorData !== undefined) {
			setData(donorData?.users);
			setTotalDoc(donorData?.count);
		}
	}, [donorData]);

	const { mutate: loadMoreAction, isLoading } = useMutation(
		getAllDonorListReqeustLoadMore,
		{
			onSuccess: (res) => {
				setData((p) => {
					return [...p, ...res];
				});
			},
		}
	);

	useEffect(() => {
		if (isLoading) {
			window.scrollTo(1000000, 1000000);
		}
	}, [isLoading]);


	const { mutate:blockHandlerAction } = useMutation(blockHandlerRequest, {
		
		onSuccess:() => {
			queryClient.invalidateQueries("donor-list")
			toast.success('operation successfull', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		},
		onError:err => {
			toast.error(err.response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	})


	function blockHandler({ isBan , id }){
		blockHandlerAction({isBan , id  })
	}


	const columns = [
		{
			width:'50px',
			name: "SL",
			selector: (row, index) => <>{index + 1}</>,
		},
		{
			width:'150px',
			name: "Donor Name",
			selector: (row) => row.name,
		},
		{
			width:'50px',
			name: "Blood Group",
			selector: (row) => row.blood,
		},
		{
			name: "District",
			selector: (row) => row.district,
		},
		{
			name: "Phone",
			selector: (row) => <> <span style={{background:"#efefef",padding:"1px 2px"}}>{row.phone}</span> <br/> {row.mobile1} <br/> {row.mobile2} </>,
		},
		{
			name: "Email",
			width:'200px',
			selector: (row) => <span >  {row.email} </span>,
		},
		{
			name: "facebook",
			width:'100px',
			selector: (row) => <a target="_blank" href={row.facebook} rel="noreferrer">  Link </a>,
		},
		{
			name: "Address",
			width:'300px',
			selector: (row) => <>  {row.presentAddress} <br/> {row.parmanentAddress} </>,
		},
		{
			name: "Availibility",
			selector: (row) => (row.available ? "Public" : "Private"),
		},
		{
			name: "Last Donation",
			selector: (row) => <>
				{row.lastDonation && 
					<>{new Date(row.lastDonation).getDate()}/
					{new Date(row.lastDonation).getMonth()+1}/
					{new Date(row.lastDonation).getFullYear()}</>
				}
			
			</>,
		},
		{
			name: "Action",
			selector: (row) => (
				<>
					{row.isBan ? <button onClick={() => blockHandler({ isBan:false, id:row._id })} className="btn">un-block</button>: <button  onClick={() => blockHandler({ isBan:true , id:row._id })}  className="btn">block</button>}
					
				</>
			),
		},
	];

	//setup before functions
	let typingTimer;                //timer identifier
	let doneTypingInterval = 700;  //time in ms (1 seconds)
	function doneTyping (val) {
		//do something
		setText(val);
	}

	return (
		<>
			<div style={{ textAlign:"right" }}>
				<input onChange={e => {
					clearTimeout(typingTimer);
					typingTimer = setTimeout(() => doneTyping(e.target.value), doneTypingInterval);
				}} style={{ marginTop:20, marginRight:50,padding:5 }} placeholder="search" />
			</div>
			<div className="tableArea">
				<DataTable
					progressPending={initialLoading}
					columns={columns}
					data={data}
				/>

				{totalDoc !== data.length && (
					<div style={{ textAlign: "center" }}>
						{isLoading ? (
							<img height={50} src="./loading.gif" alt="Loading" />
						) : (
							<button
								className="btn"
								onClick={() => {
									loadMoreAction(data.length);
								}}
							>
								Load More
							</button>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default AllDonor;
